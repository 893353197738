import React from 'react'
import { I18n } from '@front/volcanion'

import { InformationDialog, ConfirmationDialog, DialogContent, FormDialog } from '@front/squirtle'
import SimulatorDialog from '@abra/elements/Popup/SimulatorDialog'

import UnauthorizedScheduleDialog from './UnauthorizedSchedule'
import TrafficAlertDialog from './TrafficAlert'
import SendCommentForm from './SendComment'
import ManualDispatchForm from './ManualDispatch'
import SendMessageDriverForm from './SendMessageDriver'
import CancelGrouped from './CancelGrouped'
import CancelOrderConfirmation from './CancelOrderConfirmation'
import ShortcutHelper from '@abra/elements/Popup/ShortcutHelper'
import shortcuts from './shortcuts'

const Dialogs = props => {
  const {
    record_id,
    onCloseScheduleInformation,
    onCloseTrafficAlertInformation,
    onCloseInformationValidation,
    handleSendComment,
    handleManualDispatch,
    handleOpenManualDispatch,
    handleSendMessageDriver,
    handleRedispatch,
    handleCancel,
  } = props

  return <>
    <FormDialog
      name={'cancel_confirmation'}
      formProps={{
        onSubmit: (values) => handleCancel(true, values?.reason, values?.cancelation_comment)
      }}
    >
      <DialogContent>
        <CancelOrderConfirmation />
      </DialogContent>
    </FormDialog>

    <ConfirmationDialog name={'cancel_order_grouped'} onConfirm={() => handleCancel(true)}>
      <DialogContent>
        <CancelGrouped />
      </DialogContent>
    </ConfirmationDialog>
    <ConfirmationDialog name={'redispatch_confirmation'} onConfirm={handleRedispatch} />
    <ConfirmationDialog name={'dispatch_confirmation'} onConfirm={() => handleOpenManualDispatch(true)} />
    <FormDialog
      name={'order_send_comment'}
      title={I18n.t('comment.operator')}
      formProps={{
        onSubmit: handleSendComment,
        initialValues: {
          title: I18n.t('comment.label', { count: 1 }),
        }
      }}
    >
      <DialogContent>
        <SendCommentForm />
      </DialogContent>
    </FormDialog>
    <FormDialog
      name={'dispatch_manual_form'}
      maxWidth='xl'
      formProps={{ onSubmit: handleManualDispatch, }}
    >
      <DialogContent>
        <ManualDispatchForm />
      </DialogContent>
    </FormDialog>

    <FormDialog
      name={'order_send_message_driver'}
      formProps={{
        onSubmit: handleSendMessageDriver,
        initialValues: { title: I18n.t('info.order') }
      }}
    >
      <DialogContent>
        <SendMessageDriverForm />
      </DialogContent>
    </FormDialog>

    <InformationDialog name={'default_dialog_information'} />

    <InformationDialog name={'traffic_alert_information'} maxWidth={'md'} onClose={onCloseTrafficAlertInformation}>
      <DialogContent>
        <TrafficAlertDialog />
      </DialogContent>
    </InformationDialog>

    <InformationDialog name={'unauthorized_schedule_information'} onClose={onCloseScheduleInformation}>
      <DialogContent>
        <UnauthorizedScheduleDialog />
      </DialogContent>
    </InformationDialog>

    <InformationDialog name={'shortcuts_helper'} maxWidth={'lg'}>
      <DialogContent>
        <ShortcutHelper shortcuts={shortcuts} />
      </DialogContent>
    </InformationDialog>

    <SimulatorDialog order_id={record_id} />

    <InformationDialog
      name={'validation_information'}
      maxWidth={'md'}
      onClose={onCloseInformationValidation}
      _header={{ textAlign: 'center', fontSize: 20, color: 'secondary.600' }}
      _description={{ sx: { textAlign: 'center', fontSize: 16, color: 'primary.600' } }}
    />
  </>
}

export default React.memo(Dialogs)
