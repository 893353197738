
import React from 'react'
import { hooks } from '@front/volcanion'
import Component from './component'


const Container = props => {
  const reason_id = hooks.useFieldValue(['reason'])
  const [reason] = hooks.useModel('reason', [reason_id], { single: true })
  const cancelation_comment_required = reason?.comment_required

  const mergedProps = {
    cancelation_comment_required
  }
  return <Component {...mergedProps} />
}

export default Container