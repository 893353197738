import { I18n } from '@front/volcanion'
import moment from 'moment'

class Callbacks {
  static handleSendCommentHandler(createComment, record_id, openNotification) {
    return async function handleSendComment(values) {
      const { text, type, title } = values
      try {
        if (!text) openNotification(I18n.t('toast.comment.error.empty'), { variant: 'warning' })
        else {
          await createComment({ data: { text, type, title, category: 'JFPNk9Qxj', orders: [record_id] } })
          openNotification(I18n.t('toast.comment.success.order'), { variant: 'success' })
        }
      } catch (err) {
        openNotification(I18n.t('toast.comment.error.default'), { variant: 'error' })
      }
    }
  }
  static handleOpenSendCommentDialogHandler(openDialog) {
    return function handleOpenSendCommentDialog() {
      openDialog('order_send_comment', { popupTitle: I18n.t('comment.operator') })
    }
  }
  static handleManualDispatchHandler(dispatchJob, order_id, job_id, openNotification) {
    return async function handleManualDispatch(values) {
      const { user_id } = values
      try {
        if (!user_id) {
          openNotification(I18n.t('toast.driver.error.code_incorrect'), { variant: 'error' })
          // TODO  throw new errors.MismatchError({ code: 'INCORECT_DRIVER_CODE' }
        } else {
          await dispatchJob({ order_id, job_id, user_id, options: { skip_blacklist: true } })
          openNotification(I18n.t('order.request_manual_dispatch'), { variant: 'info' })
        }
      } catch (err) {
        return openNotification(I18n.t('toast.manual_dispatch.error.default'), { variant: 'error' })
      }
    }
  }
  static handleOpenManualDispatchHandler(openDialog, record, handleCall) {
    return function handleOpenManualDispatch(force) {
      const transportStarted = !_.isEmpty(_.get(record, 'assigned_transport'))
        && !_.includes(['planned', 'canceled', 'complete'], _.get(record, 'assigned_transport.status'))

      if (_.isEmpty(record, 'job') && !force)
        openDialog('default_dialog_information', {
          title: I18n.t('dispatch.no_manual.title'),
          description: I18n.t('dispatch.no_manual.text')
        })
      else if (!!transportStarted && !force)
        openDialog('default_dialog_information', {
          title: I18n.t('dispatch.transport_started.title'),
          description: I18n.t('dispatch.transport_started.text'),
        })
      else if (_.get(record, 'status') === 'attributed' && !force) {
        openDialog('dispatch_confirmation', {
          title: I18n.t('order.attributed'),
          description: I18n.t('error.order_already_attributed_dispatch', { driver_code: _.get(record, 'assigned_transport.driver.info.user_client_id') }),
        })
      }
      else
        openDialog('dispatch_manual_form', {
          title: I18n.t('dispatch.manual.label', { count: 1 }),
          order_id: _.get(record, 'order_id'),
          handleCall
        })
    }
  }
  static handleOpenSendMessageDriverHandler(openDialog) {
    return function handleOpenSendMessageDriver() {
      openDialog('order_send_message_driver', { popupTitle: I18n.t('driver.alert') })
    }
  }
  static handleSendMessageDriverHandler(sendDriverMessage, driver_id, openNotification) {
    return async function handleSendMessageDriverHandler(values) {
      const { title, text } = values
      try {
        if (!text || !driver_id) {
          openNotification(I18n.t('toast.comment.error.empty'), { variant: 'warning' })
        } else {
          await sendDriverMessage({
            data: {
              type: 'alert',
              title: title,
              body: text,
              drivers: [driver_id]
            }
          })
          openNotification(I18n.t('toast.comment.success.save'), { variant: 'success' })
        }
      } catch (err) {
        openNotification(I18n.t('toast.comment.error.default'), { variant: 'error' })
      }
    }
  }
  static handleOpenRedispatchHandler(openDialog, status, driver_client_id) {
    return function handleOpenRedispatch() {
      openDialog('redispatch_confirmation', {
        title: I18n.t('order.redispatch_confirmation.title'),
        description: status === 'attributed' ? I18n.t('error.order_already_attributed_redispatch', { driver_code: driver_client_id }) : I18n.t('order.redispatch_confirmation.body'),
      })
    }
  }
  static handleRedispatchHandler(dispatchJob, job_id, order_id, openNotification) {
    return async function handleRedispatchHandler() {
      try {
        await dispatchJob({ job_id, order_id, options: { skip_blacklist: true } })
        openNotification(I18n.t('toast.message.success.reassign'), { variant: 'success' })
      } catch (e) {
        openNotification(I18n.t('toast.message.error.reassign'), { variant: 'error' })
      }
    }
  }
  static handleOpenClaimRedispatchHandler(openDialog, customerClaim, record) {
    return async function handleOpenClaimRedispatch() {
      if (!record || _.get(record, 'status') === 'complete') return
      const eta = _.get(record, 'assigned_transport.info.src_datetime') || _.get(record, 'info.src_datetime')
      const data = !!eta ? [{
        key: 'eta',
        value: moment.utc(eta).diff(moment.utc(), 'minutes'),
        type: 'duration',
        unit: 'm'
      }] : null

      customerClaim({ data: { name: 'customer_claim', data, order: _.get(record, 'order_id'), active: false } })
      if (_.get(record, 'status') !== 'canceled') return
      openDialog('redispatch_confirmation', {
        title: I18n.t('order.redispatch_confirmation.title'),
        description: I18n.t('order.redispatch_confirmation.body'),
      })
    }
  }
  static handleOpenCancelConfirmationDialogHandler(openDialog, order_id) {
    return function handleOpenCancelConfirmationDialog(driver_client_id, order_status, isGrouped) {
      if (!!isGrouped)
        openDialog('cancel_order_grouped', {
          title: I18n.t('order.cancel'),
          order_id
        })
      else {
        openDialog('cancel_confirmation', {
          title: I18n.t(`order.cancel`),
          description: order_status === 'attributed'
            ? I18n.t('error.order_already_attributed_cancel', { driver_code: driver_client_id })
            : I18n.t('order.cancel_confirm', { order_client_id: '' })
        })
      }
    }
  }
  static handleOpenShortcutHandler(openDialog) {
    return function handleOpenShortcut() {
      openDialog('shortcuts_helper')
    }
  }
  static handleOpenSimulatorHandler(openDialog) {
    return function handleOpenSimulator() {
      openDialog('simulator')
    }
  }
}


export default Callbacks
