import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => ({ record_id }) => {
  const [order] = hooks.useModel('order', [record_id], { single: true })
  const job_id = order?.job
  const requestedAt = order?.requestedAt
  const source_id = order?.source
  const destination_id = order?.destination
  const validate_filter = useCallback(() => !!job_id, [job_id])
  const watchers = [job_id]
  const getEventOptions = useCallback((event_name) => ({
    initial_filter: { job: job_id, name: event_name },
    validate_filter,
    watchers
  }), [job_id, validate_filter, watchers])
  const [[transport_started_id]] = hooks.useModelSearchList('event', 'get', getEventOptions('transport_started'))
  const [[at_source_id]] = hooks.useModelSearchList('event', 'get', getEventOptions('transport_at_source'))
  const [[picked_up_id]] = hooks.useModelSearchList('event', 'get', getEventOptions('picked_up'))
  const [[drop_off_id]] = hooks.useModelSearchList('event', 'get', getEventOptions('drop_off'))
  const [[driver_request_for_replacement_id]] = hooks.useModelSearchList('event', 'get', getEventOptions('driver_request_for_replacement'))
  const [[driver_request_for_a_not_loaded_id]] = hooks.useModelSearchList('event', 'get', getEventOptions('driver_request_for_a_not_loaded'))

  const [location_history_ids] = hooks.useModelSearchList('locationhistory', 'sample', {
    initial_filter: { job: job_id, createdAt: { '>=': requestedAt } },
    validate_filter: useCallback(() => !!job_id && !!requestedAt, [job_id, requestedAt]),
    watchers: [job_id, requestedAt]
  })

  const mergedProps = {
    drop_off_id,
    picked_up_id,
    transport_started_id,
    location_history_ids,
    at_source_id,
    source_id,
    destination_id,
    driver_request_for_a_not_loaded_id,
    driver_request_for_replacement_id
  }

  return <Component {...mergedProps} />
}

export default withContainer
