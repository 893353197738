import React from 'react'
import { hooks } from '@front/volcanion'
import _ from 'lodash'
import { HookUtils } from '@front/volcanion/utils'

const withContainer = Component => props => {
  const user_id = hooks.useFormStateValue('user_id')
  const isB2B = hooks.useFormStateValue('isB2B')
  const [call_id] = hooks.useFormState('call_id')
  const [domain_id] = hooks.useFormState('channel')
  const isReady = hooks.useFormStatus('isReady')
  const [load_type, companyservice_id, commercial_formula] = hooks.useFieldValues(['load_type', 'user.companyservice', 'user.commercial_formula'])
  const [companyservice] = hooks.useModel('companyservice', [companyservice_id], { populate: ['customerref_option'], single: true })
  const [{ payment_types, payment_types_state }] = hooks.useAuthorizedPaymentTypes({
    service: companyservice_id, load_type, user_id, commercial_formula, domain_id
  }, isReady)
  const customerrefoption_id = _.get(companyservice, 'customerref_option.customerrefoption_id')
  const required = !isB2B || _.get(companyservice, 'customerref_option.fill_ref_rule') === 'required'
  const manual_allowed = !isB2B || _.get(companyservice, 'customerref_option.manual_allowed')
  const ids = _.map(payment_types, 'paymenttype_id')
  const order_doc_required = !!isB2B && companyservice?.fill_doc_rule === 'mandatory'

  const mergedProps = {
    manual_allowed,
    required,
    order_doc_required,
    call_id,
    customerrefoption_id,
    isB2B,
    ids,
    isLoading: HookUtils.getLoadingState([payment_types_state]),
  }
  return (
    <Component {...mergedProps} />
  )
}
export default withContainer
