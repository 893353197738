import React, { useCallback } from 'react'
import { I18n } from '@front/volcanion'

import { TextField, TextComponent, ModelMenuSelectorField, ModelAutocompleteField } from '@front/squirtle'

import { Grid, Typography, StepLabel, StepContent } from '@mui/material'

const PaymentSection = ({
  manual_allowed,
  required,
  order_doc_required,
  call_id,
  customerrefoption_id,
  isB2B,
  ids,
  isLoading,
}) => <>
    <StepLabel>
      <Typography variant='h5'>{I18n.t('bank.payment_ref')}</Typography>
    </StepLabel>
    <StepContent>
      <Grid container spacing={1} rowSpacing={1.5}>
        <Grid item xs>
          <ModelMenuSelectorField
            name={'payment_type'}
            label={I18n.t('bank.payment_mode')}
            model_name={'paymenttype'}
            labelKeys={['name_translated']}
            selectFirst
            config={{ ids }}
            isLoading={isLoading}
            required
          />
        </Grid>
        <Grid item xs hidden={!isB2B}>
          {!!isB2B && <ModelAutocompleteField
            name={'customer_ref'}
            model_name={'customerref'}
            label={I18n.t('bank.b2c.customer_ref')}
            labelKeys={['client_id']}
            selectKeys={['client_id']}
            searchMode={'search'}
            searchQuery={'startsWith'}
            autocompleteKey={'client_id'}
            loadOnFocus
            loadOnMount
            creatable={!!manual_allowed}
            selectFirst={false}
            selectCreatableLabel={true}
            selectSingleOption
            debounceTime={400}
            config={{
              forced_filter: {
                client_id: { '!': null },
                parent: customerrefoption_id
              },
              validate_filter: useCallback(() => !!customerrefoption_id, [customerrefoption_id]),
              watchers: [customerrefoption_id]
            }}
            required={required}
          />}
        </Grid>
        <Grid item xs hidden={!!isB2B}>
          {!isB2B && <TextField
            label={I18n.t('bank.b2c.customer_ref')}
            name={'customer_ref'}
            emptyValue='-'
          />}
        </Grid>
        <Grid item xs>
          <TextField
            name='order_doc'
            label={I18n.t('document.b2c.id')}
            required={order_doc_required}
          />
        </Grid>
      </Grid>
      {call_id && <Grid container>
        <Grid item xs>
          <TextComponent
            value={call_id}
            label={I18n.t('call_id.label.one')}
            disabled
          />
        </Grid>
      </Grid>}
    </StepContent>
  </>

export default React.memo(PaymentSection)
